
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row mt-4">
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="card card-icon mb-4" style="background-color: #ffa31a;">
          <div class="card-body text-center"><i style="color:#fff !important;" class="i-Box-Full"></i>
            <p class="text-muted mt-2 mb-2" style="color:#fff !important;"><strong>Raw</strong></p>
            <p class="text-primary text-24 line-height-1 m-0" style="color:#fff !important;">{{ leadStats.raw | currency('', 0) }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="card card-icon mb-4" style="background-color: #cc9966;">
          <div class="card-body text-center"><i style="color:#fff !important;" class="i-Inbox-Into"></i>
            <p class="text-muted mt-2 mb-2" style="color:#fff !important;"><strong>In Progress</strong></p>
            <p class="text-primary text-24 line-height-1 m-0" style="color:#fff !important;">{{ leadStats.inprogress | currency('', 0)}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-3">
        <div class="card card-icon mb-4" style="background-color: #66b3ff;">
          <div class="card-body text-center"><i style="color:#fff !important;" class="i-Mail-Open"></i>
            <p class="text-muted mt-2 mb-2" style="color:#fff !important;"><strong>Won</strong></p>
            <p class="text-primary text-24 line-height-1 m-0" style="color:#fff !important;">{{ leadStats.won | currency('', 0) }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="card card-icon mb-4" style="background-color: #a64dff;">
          <div class="card-body text-center"><i style="color:#fff !important;" class="i-Inbox-Out"></i>
            <p class="text-muted mt-2 mb-2" style="color:#fff !important;"><strong>Lost</strong></p>
            <p class="text-primary text-24 line-height-1 m-0" style="color:#fff !important;">{{ leadStats.lost | currency('', 0) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3>Filters</h3>
      </div>
      <div class="col-md-6">
        <b-button class="btn btn-danger float-right" v-b-modal.add-lead>Add new</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label><strong>Date:</strong></label> <br/>
        <date-picker style="width: 44%;"   :lang="lang" v-model="startDate" valueType="format" :first-day-of-week="1"></date-picker> to
        <date-picker style="width: 44%;"   :lang="lang" v-model="endDate" valueType="format"  @input="getLeadsData" :first-day-of-week="1"></date-picker>
      </div>
      <div class="col-md-2">
        <label><strong>Lead Status:</strong></label> <br/>
        <select class="form-control" v-model="leadStatus" @change="getLeadsData">
          <option value="all" > -- All --  </option>
          <option value="INPROGRESS" > In progress </option>
          <option value="WON" > Won </option>
          <option value="LOST" > Lost </option>
        </select>
      </div>
      <div class="col-md-2">
        <label><strong>Level of interest:</strong></label> <br/>
        <select class="form-control" v-model="levelOfInterest" @change="getLeadsData">
          <option value="all" > -- All --  </option>
          <option value="SERIOUS" > Serious </option>
          <option value="NOTSURE" > Not Sure </option>
          <option value="NOTINTERESTED" > Not Interested </option>
          <option value="INPROGRESS" > In Progress </option>
        </select>
      </div>
      <div class="col-md-2">
        <label><strong>Responsible:</strong></label> <br/>
        <select class="form-control" v-model="responsible" @change="getLeadsData">
          <option value="0" > -- All --  </option>
          <option :value="user.id" v-for="user in users"> {{ user.name }}</option>
        </select>
      </div>
      <div class="col-md-2">
        <label><strong>Lead Source:</strong></label> <br/>
        <select class="form-control" v-model="leadSource" @change="getLeadsData">
          <option value="0" > -- All --  </option>
          <option :value="lead_source.id" v-for="lead_source in lead_sources"> {{ lead_source.name }}</option>
        </select>
      </div>
    </div>
    <br/>
    <div>
      <div>
        <b-table responsive striped hover :items="leads" :fields="columns">
          <template v-slot:cell(name)="data">
            <span v-if="data.item.firstName">{{ data.item.firstName }} </span>
            <span v-if="data.item.lastName">{{ data.item.lastName }}</span>
          </template>

          <template v-slot:cell(leadSource)="data">
            <span v-if="data.item.leadSource">{{ data.item.leadSource.name }}</span>
          </template>

          <template v-slot:cell(status)="data">
            <b-dropdown size="sm" text="Small" variant="outline-dark">
              <template #button-content>
                <span class="badge badge-warning" v-if="data.item.status == 'RAW'"> Raw </span>
                <span class="badge badge-primary" v-else-if="data.item.status == 'INPROGRESS'"> In Progress </span>
                <span class="badge badge-success" v-else-if="data.item.status == 'WON'"> Won </span>
                <span class="badge badge-danger" v-else-if="data.item.status == 'LOST'"> Lost </span>
              </template>
              <b-dropdown-item v-if="data.value != 'WON'"> Select Status</b-dropdown-item>
              <b-dropdown-divider v-if="data.value != 'WON'"></b-dropdown-divider>
              <b-dropdown-item v-if="data.value != 'WON'" @click="openUpdateLeadStatusModal(data.item,'RAW')"><span class="badge badge-warning"> Raw </span></b-dropdown-item>
              <b-dropdown-item v-if="data.value != 'WON'" @click="openUpdateLeadStatusModal(data.item,'INPROGRESS')"><span  class="badge badge-primary"> In Progress </span></b-dropdown-item>
              <b-dropdown-item v-if="data.value != 'WON'" @click="openUpdateLeadStatusModal(data.item,'WON')"><span  class="badge badge-success"> Won </span></b-dropdown-item>
              <b-dropdown-item v-if="data.value != 'WON'" @click="openUpdateLeadStatusModal(data.item,'LOST')"><span  class="badge badge-danger"> Lost </span></b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-slot:cell(levelOfInterest)="data">
            <span class="badge badge-warning" v-if="data.item.levelOfInterest == 'NOTSURE'"> Not Sure </span>
            <span class="badge badge-primary" v-else-if="data.item.levelOfInterest == 'INPROGRESS'"> In Progress </span>
            <span class="badge badge-success" v-else-if="data.item.levelOfInterest == 'SERIOUS'"> Serious </span>
            <span class="badge badge-danger" v-else-if="data.item.levelOfInterest == 'NOTINTERESTED'"> Not Interested </span>
          </template>

          <template v-slot:cell(responsible)="data">
            <span v-if="data.item.responsible">{{ data.item.responsible.name }}</span>
          </template>

          <template v-slot:cell(options)="data">
            <b-dropdown text="Action">
              <b-dropdown-item href="#" @click="openEditLeadModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" @click="openDeleteLead(data.item)">Delete</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>

      <div class="row">
        <div class="col-md-12">
          <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
            <ul class="pagination justify-content-center">
              <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
              <li v-for="page in pagesNumber"
                  v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
              <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
        </div>
      </div>

    </div>

    <b-modal id="add-lead" title="Add Lead" @cancel="true" @ok="validateBeforeSubmitNewLead"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">First Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.firstName" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Last Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.lastName" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.phoneNumber" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Email Address:</label> <br/>
                <div>
                  <input class="form-control" type="text"  v-model="lead.emailAddress" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Company Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.companyName" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Lead Source:</label> <br/>
                <div >
                  <select class="form-control" v-model="lead.leadSource" >
                    <option :value="lead_source.id" v-for="lead_source in lead_sources"> {{ lead_source.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Level of interest:</label> <br/>
                <div >
                  <select class="form-control" v-model="lead.levelOfInterest" >
                    <option value="SERIOUS" > Serious</option>
                    <option value="NOTSURE" > Not sure</option>
                    <option value="NOTINTERESTED" > Not interested</option>
                    <option value="INPROGRESS" > In progress</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Location:</label> <br/>
                <div >
                  <select class="form-control" v-model="lead.locationId">
                    <option :value="location.id" v-for="location in locations"> {{ location.locationName }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Products of interest:</label> <br/>
                <div >
                  <multiselect :multiple="true" v-model="lead.products" :options="products" label="productName" track-by="productName"></multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Comments / Client Feedback:</label> <br/>
                <div >
                  <textarea class="form-control" rows="5" style="width: 100%;"  v-model="lead.comments"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>
    <b-modal id="edit-lead" title="Edit Lead" @cancel="true" @ok="validateBeforeSubmitEditLead"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">First Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.firstName" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Last Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.lastName" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.phoneNumber" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Email Address:</label> <br/>
                <div>
                  <input class="form-control" type="text"  v-model="lead.emailAddress" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Company Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead.companyName" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Lead Source:</label> <br/>
                <div >
                  <select class="form-control" v-model="lead.leadSource" >
                    <option :value="lead_source.id" v-for="lead_source in lead_sources"> {{ lead_source.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Level of interest:</label> <br/>
                <div >
                  <select class="form-control" v-model="lead.levelOfInterest" >
                    <option value="SERIOUS" > Serious</option>
                    <option value="NOTSURE" > Not sure</option>
                    <option value="NOTINTERESTED" > Not interested</option>
                    <option value="INPROGRESS" > In progress</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Location:</label> <br/>
                <div >
                  <select class="form-control" v-model="lead.locationId">
                    <option :value="location.id" v-for="location in locations"> {{ location.locationName }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Products of interest:</label> <br/>
                <div >
                  <multiselect :multiple="true" v-model="lead.products" :options="products" label="productName" track-by="productName"></multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>
    <b-modal id="update-lead-status"  title="Update Lead Status" @cancel="true" @ok="submitLeadStatusUpdate"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Status:</label> <br/>
                <div >
                  <label>
                    <input type="radio" v-model="leadStatusUpdate.status" value="RAW" /> Raw
                  </label>
                </div>
                <div >
                  <label>
                    <input type="radio" v-model="leadStatusUpdate.status" value="INPROGRESS" /> In progress
                  </label>
                </div>
                <div >
                  <label>
                    <input type="radio" v-model="leadStatusUpdate.status" value="WON" /> Won
                  </label>
                </div>
                <div >
                  <label>
                    <input type="radio" v-model="leadStatusUpdate.status" value="LOST" /> Lost
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Comments:</label> <br/>
                <div >
                  <textarea class="form-control" v-model="leadStatusUpdate.comment" style="width: 100%" rows="6">

                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Multiselect from 'vue-multiselect'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

export default {
  data() {
    return {
      leadStats:{
        raw:0,
        inprogress:0,
        won:0,
        lost:0
      },
      value: null,
      isLoading: false,
      fullPage: true,
      offset: 4,
      leadStatusUpdate:{
        leadId:0,
        comment:"",
        status:""
      },
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      leads:[],
      lead_sources:[],
      locations:[],
      products:[],
      users:[],
      lead:{
        id:0,
        firstName:"",
        lastName:"",
        phoneNumber:"",
        emailAddress:"",
        leadSource:0,
        companyName:"",
        levelOfInterest:"",
        products:[],
        comments:"",
        locationId:0
      },
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      startDate:"all",
      endDate:"all",
      leadStatus:"all",
      leadSource:0,
      responsible:0,
      levelOfInterest:"all",
      columns:[{ key: 'dateCreated', label: 'Date' },"name","phoneNumber","emailAddress","companyName","leadSource","status","levelOfInterest","responsible","products","options"]
    };
  },
  components: {
    Loading,
    Multiselect,
    DatePicker
  },
  computed: {
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getLeadsData();
    this.getLocationsData();
    this.getLeadSourceData();
    this.getProductsData();
    this.getUsersData();
  },
  methods: {
    ...mapActions(["getLeadStats","addLead", "editLead", "deleteLead", "getLeads", "getLeadSources", "getLocations","getProducts","getUsers","updateLeadStatus"]),
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getLeadsData();
    },
    getLeadSourceData(){
      let self = this;
      this.getLeadSources()
        .then(function (lead_sources) {
          self.lead_sources = lead_sources;

        })
        .catch(function (error) {

        })
    },
    getLocationsData(){

      let self = this;
      this.getLocations()
        .then(function (locations) {
          self.locations = locations;
        })
        .catch(function (error) {


        })
    },
    getLeadStatsData(){
      let self = this;
      this.getLeadStats({startDate: this.startDate, endDate: this.endDate})
          .then(function (leadStats) {
            self.leadStats = leadStats;
          })
          .catch(function (error) {
            self.isLoading = false;
          })
    },
    getLeadsData(){
      let self = this;
      this.isLoading = true;
      this.getLeads({startDate: this.startDate, endDate: this.endDate, leadStatus: this.leadStatus, leadSource: this.leadSource, responsible: this.responsible, levelOfInterest:this.levelOfInterest, page: this.pagination.current_page})
        .then(function (leads) {
          self.isLoading = false;
          self.leads = leads.content;
          if(leads.content){
            self.pagination.current_page = leads.number;
            self.pagination.total = leads.totalElements;
            self.pagination.per_page = leads.numberOfElements;
            self.pagination.from = leads.pageable.offset + 1 ;
            self.pagination.to = leads.pageable.pageSize;
            self.pagination.last_page = leads.totalPages ;
          }
        })
        .catch(function (error) {
          self.isLoading = false;
        });

      this.getLeadStatsData()
    },
    getUsersData(){
      let self = this;
      this.getUsers()
          .then(function (users) {
            self.users = users;
          })
          .catch(function (error) {

          })
    },
    getProductsData(){
      let self = this;
      this.getProducts()
          .then(function (products) {
            //console.log(products);
            self.products = products;

          })
          .catch(function (error) {



          })
    },
    confirmDeleteContact(){
      this.isLoading = true;
      let self = this;
      this.deleteContact(this.contact).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Contact deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getContactsData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })

    },
    confirmDeleteLead(){
      this.isLoading = true;
      let self = this;
      this.deleteLead(this.lead).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadsData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })

    },
    openEditLeadModal(lead){
      this.lead = lead;
      if(lead.leadSource) {
        this.lead.leadSource = lead.leadSource.id;
      }
      if(lead.location) {
        this.lead.locationId = lead.location.id;
      }

      this.$bvModal.show('edit-lead');
    },
    openUpdateLeadStatusModal(lead, status){
      this.leadStatusUpdate.leadId = lead.id;
      this.leadStatusUpdate.status = status;

      this.$bvModal.show('update-lead-status');
    },
    openDeleteLead(lead){

      this.lead = lead;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.lead.firstName +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteLead();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    validateBeforeSubmitNewLead(){
      this.isLoading = true;

      let self = this;

     this.lead.products = this.lead.products.map(function (product){
       return product.id
     })

      console.log(this.lead);

      this.addLead(this.lead).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadsData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },
    validateBeforeSubmitEditLead(){
      this.isLoading = true;

      let self = this;
      this.editLead(this.lead).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead updated", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadsData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },
    submitLeadStatusUpdate(){
      this.isLoading = true;

      let self = this;
      this.updateLeadStatus(this.leadStatusUpdate).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead updated", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadsData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
